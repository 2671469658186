// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeWrapper {
  background: radial-gradient(circle, s rgb(54, 78, 128) 30%, rgb(25, 44, 76) 100%);
}

.formSectionSecond:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Home/Home.scss"],"names":[],"mappings":"AAAA;EACI,iFAAA;AACJ;;AAOI;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,4BAAA;EACA,UAAA;AAJR","sourcesContent":[".homeWrapper {\n    background: radial-gradient(circle,\n            s rgba(54, 78, 128, 1) 30%,\n            rgba(25, 44, 76, 1) 100%);\n}\n\n.formSectionSecond {\n    // background: radial-gradient(circle, rgba(54, 78, 128, 1) 0%, rgba(25, 44, 76, 1) 35%);\n\n    &:before {\n        content: '';\n        position: absolute;\n        width: 100%;\n        height: 100%;\n        top: 0;\n        left: 0;\n        background-repeat: no-repeat;\n        z-index: 0;\n        // background-image: url('../../../Assets//images/BackgroundHome2.svg');\n        // background-image: url('../../../Assets/home/PentagonDesign2.svg');\n    }\n\n    // background-repeat: no-repeat;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
