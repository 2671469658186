//dependencies
import { Box } from "@mui/material";
//styles
import "./TermsConditions.scss";

const TermsConditions = () => {
  return (
    <>
      <Box className="TermsConditions">
        <h3 className="section-title">Terms and Conditions</h3>
        <p>
          By entering and using this website, whose domain name is
          www.qsolutionsmx.com, owned by Asesores de Telemercadeo QSMX, S.A. DE
          C.V., hereinafter referred to as “QSMX,” the user is accepting the
          terms and conditions, hereinafter referred to as “T&C” of use
          contained in this agreement and declares its acceptance using, for
          this purpose, electronic means, in terms of the provisions of article
          1803 of the Federal Civil Code.
        </p>
        <p>
          If the T&C of this agreement are not accepted, the user must refrain
          from accessing and using the website www.qualifiedsolutions.com.
        </p>
        <p>
          If the user gets access or uses the website, it will be considered as
          an acceptance of the T&C herein stipulated.
        </p>
        <p>
          The use of this website provides the general public with the status of
          a user (hereinafter referred to as the “user” or “users”). It implies
          the acceptance, complete and unconditional, of every one of the
          general and particular conditions Included in these T&C published by
          QSMX when the user uses this website.
        </p>
        <h3 className="section-title">Our Management System Policy</h3>
        <p>
          QSMX operates through a continuous improvement system aimed at
          achieving quality levels and standards established with the Client,
          information security, and the highest sense of ethics and integrity,
          providing services of processes based on "Contact Center," promoting a
          thought based on a culture of prevention and rejection of public or
          private bribery, while complying with the strategic objectives of the
          organization, within the current legal framework.
        </p>
        <h3 className="section-title">License</h3>
        <p>
          By this agreement, QSMX grants to the User the non-exclusive,
          revocable and non-transferable right to view and use the website
          through the T&C stipulated herein. For the effects of This agreement,
          the Parties agree that “user” shall mean any person or organization of
          any nature who enters the website and any of the sub-pages that
          display its content and the person that is discharged and uses any of
          the services offered through this page.
        </p>
        <p>
          The user may only print and copy any information contained or
          published on the website exclusively for personal use; the commercial
          use of such information is strictly prohibited. In the case of being
          an organization, it shall be subject to the provisions of article 148,
          section IV of the Federal Copyright Law.
        </p>
        <p>
          The reprinting, publication, distribution, assignment, sublicense,
          sale, electronic reproduction or other means, partial or total, of any
          information, document or graphic that appears on the website
          www.qsolutionsmx.com, for any use other than Non-commercial are
          prohibited unless there is prior written permission from QSMX.
        </p>
        <h3 className="section-title">Rules for the use of the website</h3>
        <p>
          The User and QSMX agree that the use of the website will be subject to
          the following rules:
        </p>
        <p>
          Information contained on the website www.qsolutionsmx.com. The User
          acknowledges and accepts that the information published or contained
          in that site will be clearly identified in such a way that it is
          recognized that it comes from and has been generated by QSMX or its
          suppliers.
        </p>
        <p>
          However, the information, concepts, and opinions published on the site
          do not necessarily reflect the position of QSMX, or its employees,
          officers, directors, shareholders, licensees, and concessionaires,
          hereinafter the “Affiliates". For this reason, QSMX is not responsible
          for any of the information, opinions, and concepts that are emitted on
          the website concerned. In this case, the User is encouraged to consult
          with a specialist and professional in the field. In addition, it is
          not responsible for the information on the website, including
          sub-pages, in the understanding that the use and monitoring of the
          same are at risk and the user's responsibility.
        </p>
        <p>
          QSMX reserves the right to block access to or wholly or partially
          remove any information, communication, or material that at its sole
          discretion may result: I) abusive, defamatory, or obscene; II)
          fraudulent, contrived or misleading; III) Violation of copyright,
          trademarks, confidentiality, industrial secrets, or any intellectual
          property rights of a third party; IV) offensive or; V) that
          contravenes the provisions of this agreement. If a User wishes to get
          more information from a specific subject provided by QSMX or its
          suppliers. In that case, he/she must consult it directly with each of
          them, as appropriate, and with a specialist.
        </p>
        <p>
          The User recognizes that QSMX does not control or pre-censor the
          content available on the website. For this reason, QSMX assumes no
          responsibility for the content provided to this page by independent or
          non-QSMX suppliers and does not have editorial control over the
          content, information, and material generated and provided by third
          parties. All opinions, advice, declarations, services, offers or other
          data or contents expressed or made available to the public by third
          parties belong to their respective authors, and QSMX assumes no
          responsibility whatsoever. In the same way, QSMX does not guarantee
          the accuracy, integrity, breadth, and usefulness of any content
          provided by such third parties. Additionally, QSMX is not responsible
          or warrants the accuracy, completeness, truthfulness, and reliability
          of any opinion, information, advice, or statement expressed by QSMX
          through its website, and under no circumstances shall it be
          responsible for any damage and prejudice, direct or indirect, caused
          by the trust that the user deposits in information obtained through
          its web site. QSMX reserves the right to delete or modify the content
          of this page which, at the sole discretion of the QSMX, does not meet
          its standards or which may be contrary to the current legal system and
          shall therefore not be liable for any failure or delay that is
          generated by eliminating such material.
        </p>
        <h3 className="section-title">Formats</h3>
        <p>
          Users acknowledge that, by providing the required personal information
          in any of the services offered on this website, they authorize QSMX in
          terms of article 109 of the Federal Copyright Law. In all cases, Users
          will be responsible for the integrity of the information provided to
          QSMX.
        </p>
        <p>
          The User is obliged to accept the T&C stipulated for that purpose for
          the provision of services.
        </p>
        <h3 className="section-title">Copyright and Industrial Property</h3>
        <p>
          QSMX, the website www.qsolutionsmx.com, its logos and all the material
          on the site, trademarks, domain names, trade names, and artistic works
          owned by their respective owners are protected by international
          treaties and applicable laws intellectual property and copyright.
        </p>
        <p>
          Copyright on content, organization, compilation, information, logos,
          photographs, images, programs, applications, and in general any
          information contained or published on the website are duly protected
          in favor of QSMX, its affiliates, suppliers, and their respective
          owners, by the applicable legislation in intellectual and industrial
          property matters.
        </p>
        <p>
          If the User transmits any information, programs, applications,
          software, or in general any material that requires to be licensed
          through the website www.qsolutionsmx.com, the User grants, with this
          act, a perpetual license to QSMX, universal, free, non-exclusive,
          global and royalty-free, which includes the rights of sublicensing,
          selling, reproducing, distributing, transmitting, creating derivative
          works, displaying and executing them publicly.
        </p>
        <p>
          The provisions of the preceding paragraph shall also apply to any
          other information that the user submits or transmits to QSMX,
          including, without limitation, ideas to renew or improve
          www.qsolutionsmx.com website, whether they have been included in any
          space on the designated page or by other known or developed means or
          modes of transmission in the future.
        </p>
        <p>
          The User waives this act to carry out any action, claim or claim
          against QSMX, its affiliates, or suppliers for any current or eventual
          violation of any copyright or intellectual property rights derivative
          of information, programs, applications, software, ideas, and other
          material that the user submits to the website.
        </p>
        <p>
          Our policy is to act against violations that may be generated or
          originated by intellectual property as stipulated in the legislation
          and other applicable intellectual property laws, including the
          elimination or blocking of access to material that is subject to
          activities that infringe the intellectual property rights of third
          parties.
        </p>
        <p>
          Indicating: I) correct personal data (name, address, telephone number,
          and email address of the claimant); (II) signature with the personal
          data of the intellectual property rights holder; (III) Accurate and
          complete indication of the protected content(s) through the allegedly
          infringed intellectual property rights, as well as the location of
          such violations on the website concerned; Express and a clear
          statement that the introduction of the content(s) indicated has been
          made without the consent of the owner of the intellectual property
          rights allegedly infringed; Express, clear, and under the claimant’s
          responsibility that the information provided in the notice is accurate
          and that the introduction of the content(s) constitutes a violation of
          such rights.
        </p>
        <h3 className="section-title">Copyright and Industrial Property</h3>
        <p>Advertising Material</p>
        <p>
          The User acknowledges and accepts that QSMX is an independent
          organization of third party sponsors and advertisers whose
          information, images, advertisements, and other advertising or
          promotional material, hereinafter “Advertising material,” may be
          published on the website.
        </p>
        <p>
          The User acknowledges and accepts that the advertising material is not
          part of the main content published on that site. It also recognizes
          and takes with this act that this material is protected by laws
          applicable in terms of intellectual and industrial property.
        </p>
        <h3 className="section-title">Disclaimer of Warranties</h3>
        <p>
          The User agrees that the use of the website is at their own risk and
          that the services and/or products provided and/or offered are foreseen
          on an “as is” and “as available” basis. QSMX does not guarantee that
          the website satisfies the user’s requirements or that the services
          offered are not interrupted, safe, or error-free.
        </p>
        <p>
          QSMX does not guarantee or endorse in any way the integrity, accuracy,
          legality, morality, or any other characteristic of the content of the
          material that is published on the website
        </p>
        <p>
          QSMX is free of any liability and conditions, both express and
          implied, about the services and information contained or available on
          or through this website; including, without limitation:
        </p>
        <ul>
          <li>Availability of website use</li>
          <li>
            The absence of viruses, errors, deactivators, or any other
            contaminating material or destructive functions in the information
            or programs available on or through this page or any failure on that
            site.
          </li>
          <li>
            Notwithstanding the preceding, QSMX or its suppliers may update the
            page's content constantly, so the user is requested to consider that
            some information advertised or contained in or through this website
            may have been obsolete and contain inaccuracies, typographical, or
            orthographic errors.
          </li>
        </ul>
        <h3 className="section-title">Limitations on Liability</h3>
        <p>
          To the maximum extent permitted by applicable law, it shall not, in
          any event, be liable for direct, special, incidental, indirect, or
          consequential damages in any form arising out of or in connection
          with:
        </p>
        <ul>
          <li>
            The use or execution of the website www.qsolutionsmx.com, with the
            delay or lack of availability of use of QSMX.
          </li>
          <li>
            The supply or lack of the same of services of any information or
            graphics contained or published in or through the designated site.
          </li>
          <li>Updating or failure to update the information.</li>
          <li>
            Alteration or modification, in whole or in part, of the information
            after it has been included in that site.
          </li>
          <li>
            Any other aspect or characteristic of the information contained or
            published on the website or through the links that are eventually
            included in this site.
          </li>
          <li>
            The supply or lack of supply that the other services, all previous
            assumptions will be in force, even in cases where you have been
            notified or advised to QSMX about the possibility of such damages.
          </li>
        </ul>
        <h3 className="section-title">International Access</h3>
        <p>
          This site can be accessed from any country. This site may contain
          products or references to products not available outside the Mexican
          United States, hereinafter “Mexico”. These references do not imply
          that these products will be available outside Mexico. If the User
          accesses and uses this site outside of Mexico, is responsible for
          complying with local laws and regulations.
        </p>
        <h3 className="section-title">Underage</h3>
        <p>
          To make use of the portal and services, minors must obtain prior
          permission from their parents, guardians, or legal representatives,
          who will be held responsible for all acts performed by the children in
          their charge. The entire responsibility for determining the contents
          and services to which the minors accessed corresponds to the elders to
          whom they are responsible. The fact of children browsing and using the
          portal implies that they have the mentioned authorization, releasing
          QSMX of any responsibility.
        </p>
        <h3 className="section-title">Changes to the Website</h3>
        <p>
          QSMX may, at any time and when it deems appropriate, without the need
          to notify the user, make corrections, additions, improvements, or
          modifications to the content, presentation, information, services,
          areas, databases, and other elements of the site, without the right to
          any claim or indemnity, or that this implies recognition of any
          liability in favor of the User.
        </p>
        <h3 className="section-title">Amendments to the Agreement</h3>
        <p>
          QSMX reserves the right to modify the T&C of this agreement at any
          time, being effective these modifications immediately using:
        </p>
        <ul>
          <li>The publication on the website of the amended agreement.</li>
          <li>Notification to the User of such modifications.</li>
          <li>
            The User agrees to review the agreement periodically to keep abreast
            of such modifications. Notwithstanding the preceding, each time the
            User accesses the designated site, it shall be regarded as an
            absolute acceptance of the modifications of this Agreement
          </li>
        </ul>
        <h3 className="section-title">Additional Terms</h3>
        <p>
          Occasionally, QSMX may add to the T&C of this Agreement additional
          provisions relating to specific areas or new services provided on or
          through the website in the subsequent “Additional terms", which will
          be published in the particular areas or new services of that site for
          reading and acceptance. The User acknowledges and accepts that these
          additional terms are an integral part of this Agreement for all legal
          purposes to which there is a place.
        </p>
        <h3 className="section-title">Assignment of Rights</h3>
        <p>
          QSMX may give in whole or in part its rights and obligations arising
          from this Agreement at any time and when it deems appropriate. By such
          transfer, QSMX shall be released from any responsibility in favor of
          the User, as outlined in this Agreement.
        </p>
        <h3 className="section-title">Indemnification</h3>
        <p>
          The User agrees to indemnify QSMX, its affiliates, suppliers, vendors,
          and advisors for any action, demand, or claim (including attorney’s
          fees and legal costs) arising out of any breach by the user of this
          Agreement; including, without limitation of any of the derivative of:
        </p>
        <ul>
          <li>Any aspect relating to the use of the website.</li>
          <li>
            The information contained or available on or through this site or of
            libel, defamation, or any other conduct violating this agreement by
            the user in the use of the website indicated.
          </li>
          <li>
            Violation of applicable laws or international treaties relating to
            copyright or intellectual property, contained or available on or
            through such website.
          </li>
        </ul>
        <h3 className="section-title">Termination</h3>
        <p>
          QSMX reserves the right, in its sole discretion, and without the need
          for notice or notification to the user, to:
        </p>
        <ul>
          <li> Terminate this Agreement.</li>
          <li>
            Discontinue or stop posting the website definitively without
            liability to QSMX, its affiliates, or suppliers.
          </li>
        </ul>
        <h3 className="section-title">Supersede</h3>
        <p>
          These T&C and the Additional Terms constitute the entire agreement
          between the parties and supersede any other agreement or agreement
          previously concluded. Any clause or provision of this Agreement and
          the Additional Terms, legally declared invalid, shall be eliminated or
          modified at the option of QSMX to correct its vice or defect. However,
          the rest of the provisions will maintain their strength,
          enforceability, and validity.
        </p>
        <h3 className="section-title">No Waiver of Rights</h3>
        <p>
          Inactivity by the QSMX, its affiliates, or suppliers to the exercise
          of any right or action deriving from this Agreement shall, at no time,
          be construed as a waiver of such rights or actions.
        </p>
        <h3 className="section-title">Applicable Law and Jurisdiction</h3>
        <p>
          This Agreement shall be subject to and interpreted in accordance with
          the laws and courts of Mexico City.
        </p>
        <h3 className="section-title">Cookies Policy</h3>
        <p>
          This online Cookies Policy (the “Policy”) applies to all data gathered
          by QSMX and its affiliates and subsidiaries, (collectively “QSMX
          Content”). The purpose of this Policy is to provide you with
          information about the collection, use, maintenance, and disclosure of
          your information by QSMX Content.
        </p>
        <h3 className="section-title">What are Cookies?</h3>
        <p>
          As stated in our Privacy Policy, a cookie is an element of data that a
          website can send to your browser, which may then store the cookie on
          your hard drive. When a cookie is stored on your computer’s hard
          drive, the next time you visit the same website, we can recognize you.
        </p>
        <h3 className="section-title">How are Cookies Used?</h3>
        <p>Strictly Necessary Cookies</p>
        <p>
          When you view our website or our customer’s hosted websites, QSMX
          Content’s software will immediately store some information, in the
          form of essential cookies, on your device. These cookies are essential
          to running the site you are visiting and are essential to QSMX
          Content’s ability to provide services to its customers. QSMX Content’s
          essential cookies are used to allow you to access certain features of
          the website and display personalized content.
        </p>
        <p>Performance Cookies</p>
        <p>
          Performance cookies are used to identify you from your previous visits
          to the site. They will also allow QSMX Content and its customers to
          see how you use and interact with website. This information will help
          QSMX Content optimize your experience and make it easier for you to
          navigate. Performance cookies do not collect information that
          identifies you.
        </p>
        <p>Functional Cookies</p>
        <p>
          QSMX Content and its customers may choose to use additional cookies in
          an effort to improve your visit. Functional cookies remember specific
          settings you have applied and/or answers to quizzes and assessments.
        </p>
        <p>Cookies opt-out</p>
        <p>
          If you wish not to have additional cookies, beyond those described
          above as Strictly Necessary cookies, placed on your device, then you
          would not be able to use the QSMX Content and/or customer website,
          make use of any tools QSMX Content and/or the customer provides, and,
          if available, select the opt-out prompt that has appeared on the
          website. To erase any Strictly Necessary cookies that have already
          been placed on your device, you can remove these from your computer
          hard drives by referring to your browser instructions or help screen.
          Please note that certain websites and/or website functionality may not
          work if you opt-out or if cookies are blocked.
        </p>
        <p>Disclaimer</p>
        <p>
          QSMX reserves the rights to modify its cookie policy at any time and
          will notify you by posting an amendment to the cookie policy on our
          website. Such modifications will become effective on the day they are
          posted. QSMX Content encourages you to frequently review the cookie
          policy for any modifications.
        </p>
      </Box>
    </>
  );
};

export default TermsConditions;
