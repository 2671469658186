// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TermsConditions {
  padding: 50px;
}
.TermsConditions .section-title {
  color: #192c4c;
  margin: 50px 0;
}
.TermsConditions p {
  color: #192c4c;
  font-size: 18px;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 25px;
}
.TermsConditions ul {
  padding-left: 30px;
}
.TermsConditions ul li {
  color: #192c4c;
  font-size: 18px;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1366px) {
  .TermsConditions {
    padding: 30px;
  }
  .TermsConditions .section-title {
    margin: 30px 0;
  }
}
@media only screen and (max-width: 575px) {
  .TermsConditions {
    padding: 15px;
  }
  .TermsConditions .section-title {
    margin: 30px 0;
  }
  .TermsConditions p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .TermsConditions ul li {
    font-size: 16px;
    margin-bottom: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/TermsConditions/TermsConditions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,cAAA;EACA,cAAA;AAEJ;AAAE;EACE,cAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AADI;EACE,cAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAGN;;AAEA;EACE;IACE,aAAA;EACF;EAAE;IACE,cAAA;EAEJ;AACF;AACA;EACE;IACE,aAAA;EACF;EAAE;IACE,cAAA;EAEJ;EAAE;IACE,eAAA;IACA,mBAAA;EAEJ;EACI;IACE,eAAA;IACA,kBAAA;EACN;AACF","sourcesContent":[".TermsConditions {\n  padding: 50px;\n  .section-title {\n    color: #192c4c;\n    margin: 50px 0;\n  }\n  p {\n    color: #192c4c;\n    font-size: 18px;\n    line-height: normal;\n    margin-top: 0;\n    margin-bottom: 25px;\n  }\n  ul {\n    padding-left: 30px;\n    li {\n      color: #192c4c;\n      font-size: 18px;\n      line-height: normal;\n      margin-top: 0;\n      margin-bottom: 15px;\n    }\n  }\n}\n\n@media only screen and (max-width: 1366px) {\n  .TermsConditions {\n    padding: 30px;\n    .section-title {\n      margin: 30px 0;\n    }\n  }\n}\n@media only screen and (max-width: 575px) {\n  .TermsConditions {\n    padding: 15px;\n    .section-title {\n      margin: 30px 0;\n    }\n    p {\n      font-size: 16px;\n      margin-bottom: 15px;\n    }\n    ul {\n      li {\n        font-size: 16px;\n        margin-bottom: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
